import React from 'react'
import Header from './Components/Header'
import Main from './Components/Main'
import Footer from './Components/Footer'

function App() {
    return (
        <div className="w-full">
            <Header />
            <Main />
            <Footer />
        </div>
    );
}

export default App;
